import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = { interval: Number };

  connect() {
    setInterval(() => {
      this.element.reload();
    }, this.intervalValue);
  }
}
